import React from 'react'

const Terms = () => {
    return (
        <div className='custom-container terms py-6'>
            <div className="border border-[#393939] rounded-md flex items-center overflow-hidden">
                <button className="text-white p-4 tab-btn active rounded border-r border-[#393939]">
                    Terms of Use
                </button>
            </div>
            <p className='mt-10 text-lg'>
                These Terms of Use ("Terms") govern your use of the MULTICHAIN MEV BOT website and services provided through it. By accessing or using our website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms. <br/><br/>

                Use of the MULTICHAIN MEV BOT: 
                <br/> <br/>
                1.1 Eligibility: You must be of legal age and have the legal capacity to use our services. By using our services, you represent and warrant that you meet these eligibility requirements. 
                <br/>
                1.2 Wallet Connection: To use our services, you must connect your wallet to the MULTICHAIN MEV BOT. By doing so, you authorize us to access and process the necessary information related to your wallet. 
                <br/>
                1.3 MEV Bot Performance: Our MULTICHAIN MEV BOT aims to generate profits for users by taking advantage of Miner Extractable Value (MEV) opportunities. However, we do not guarantee any specific returns or profitability. The performance of the MEV bot may vary and is subject to market conditions and other factors beyond our control.
                <br/>
                1.4 Fee Structure: We charge a fee ranging from 7% to 15% of the profits generated by the MEV Bot. By using our services, you acknowledge and agree to the fee structure as stated.

                <br/>
                <br/>
                2.	Risks and Limitations:
                <br/>
                2.1 Investment Risks: The use of our services and the participation in MEV opportunities involve inherent risks associated with the volatile nature of cryptocurrency markets. You understand and accept that there are potential financial risks, including the loss of your invested funds.
                <br/>
                2.2 No Financial Advice: We do not provide financial, investment, or legal advice. The information provided through our website and services is for informational purposes only. You are solely responsible for making your own investment decisions.
                <br/><br/>
                3.	Prohibited Conduct: When using the MULTICHAIN MEV BOT, you agree to the following:
                <br/>
                3.1 Compliance with Laws: You will comply with all applicable laws, regulations, and third-party rights.
                <br/>
                3.2 Prohibited Activities: You will not engage in any illegal, fraudulent, or unauthorized activities, including but not limited to hacking, data mining, or interfering with the proper functioning of our website and services.
                <br/>
                3.3 Intellectual Property: You will not infringe upon our intellectual property rights or use our content for commercial purposes without obtaining prior written consent from us.
                <br/><br/>
                4.	Intellectual Property: All intellectual property rights, including but not limited to trademarks, logos, and content displayed on our website, are owned by or licensed to us. You are prohibited from using, copying, or distributing any intellectual property without our prior written permission.
                <br/><br/>
                5.	Limitation of Liability: To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, consequential, or exemplary damages arising out of or in connection with the use of our website and services. We do not assume responsibility for any losses or damages incurred as a result of your use of our services or any actions taken based on the information provided.
                <br/><br/>
                6.	Modification and Termination: We reserve the right to modify, suspend, or terminate our website and services at any time without prior notice. We may also update these Terms from time to time, and your continued use of our services will constitute your acceptance of the revised Terms.
                <br/><br/>
                7.	Governing Law and Jurisdiction: These Terms shall be governed by and construed in accordance with the laws of Republica Dominica. Any disputes arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts of Republica Dominica.
                <br/><br/>
                8.	Severability: If any provision of these Terms is deemed invalid or unenforceable, the remaining provisions shall remain in full force and effect.
                <br/><br/>
                Please note that this Privacy Policy and these Terms of Use are for informational purposes only and may require customization to accurately reflect the specific practices and legal requirements of your MULTICHAIN MEV BOT website. It is recommended to consult with a legal professional to ensure compliance with applicable laws and regulations.


            </p>
        </div>
    )
}

export default Terms