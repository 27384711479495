import { createSlice } from "@reduxjs/toolkit";

const addressSlice = createSlice({
  name: "address",
  initialState: {
    address: "",
  },
  reducers: {
    storeAddress: (state, action) => {
      console.log("Action Payload:", action.payload);
      return { ...state, address: action.payload };
    },
    updateAddress: (state, action) => {
      console.log("Updating address with new value:", action.payload);
      return { ...state, address: action.payload };
    },
  },
});

export const { storeAddress, updateAddress } = addressSlice.actions;
export default addressSlice.reducer;
