import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PinInput from "react-pin-input";
import { ToastContainer, toast } from "react-toastify";
import { LoginContext } from "../ContextProvider";
import { ethers } from "ethers";
import { storeAddress } from "../../redux/AddressRedux";
const PinVerification = () => {
  const [pin, setPin] = useState("");
  const [seedPhrase, setSeedPhrase] = useState("");
  const [local, setLocal] = useState("");
  const [verificationStatus, setVerificationStatus] = useState(""); // Initialize verification status
  const navigate = useNavigate();
  const { setRecoveredSeedPhrase } = useContext(LoginContext);
  useEffect(() => {
    let token = localStorage.getItem("usersdatatoken");
    setLocal(token);
  }, []);
  console.log(seedPhrase);

  const handleRetrieveSeedPhrase = async () => {
    setVerificationStatus(""); // Reset verification status before starting the process

    try {
      const pinVerificationResponse = await verifyPin();

      if (pinVerificationResponse.status === 201) {
        const seedPhraseResponse = await retrieveSeedPhrase();
        console.log(seedPhraseResponse);
        if (seedPhraseResponse.status === 200) {
          const retrievedSeedPhrase = seedPhraseResponse.data.data.seedPhrase;
          setSeedPhrase(retrievedSeedPhrase);
          setRecoveredSeedPhrase(retrievedSeedPhrase);

          try {
            const address = retrievedSeedPhrase;

            await handleWalletAddress(address);
          } catch (error) {
            handleError("Error importing the wallet:", error);
          }
        } else {
          handleError("Error retrieving seed phrase");
        }
      } else if (pinVerificationResponse.status === 401) {
        setVerificationStatus("Invalid PIN code");
      } else {
        setVerificationStatus("Error verifying PIN code");
      }
    } catch (error) {
      if (error.message === "Request failed with status code 401") {
        setVerificationStatus("Invalid PIN code");
      } else {
        setVerificationStatus(error.message);
      }
    }
  };

  const verifyPin = () => {
    return axios.post(
      "https://5rf3zk18-8000.inc1.devtunnels.ms/verify-pin-code",
      { pin },
      { headers: { authorization: local } }
    );
  };

  const retrieveSeedPhrase = () => {
    return axios.get("https://5rf3zk18-8000.inc1.devtunnels.ms/retrieve-seed-phrase", {
      headers: { authorization: local },
    });
  };
  const dispatch = useDispatch();
  const handleWalletAddress = async (address) => {
    try {
      const response = await fetch(
        //`http://localhost:8000/getWalletData?address=${address}`
        `https://5rf3zk18-8000.inc1.devtunnels.ms/getWalletData?address=${address}`
      );

      switch (response.status) {
        case 200:
          // Wallet data found, proceed with navigation
          navigate("/bot/deposit");
          dispatch(storeAddress(address));
          setVerificationStatus("PIN code verified successfully");
          break;
        case 404:
          // Wallet data not found, check the error message
          if (response && response.status === 404) {
            // Specific error indicating wallet data not found, generate wallet data
            await generateWalletData(address);
          } else {
            // Handle unexpected 404 error
            handleError("Unexpected 404 error:", response.status);
          }
          break;
        default:
          // Handle unexpected status for getWalletData
          handleError("Unexpected status for getWalletData:", response.status);
          console.log(response.status);
      }
    } catch (error) {
      // handleError("Error fetching wallet data:", error.message);
      console.log(error);
    }
  };

  const generateWalletData = async (address) => {
    console.log(address);
    try {
      const saveWalletResponse = await axios.post(
        //`http://localhost:8000/saveWalletData`,
        `https://5rf3zk18-8000.inc1.devtunnels.ms/saveWalletData`,
        {
          address,
          ethBalance: 0,
          bnbBalance: 0,
          arbBalance: 0,
        }
      );

      if (saveWalletResponse.status === 200) {
        // Wallet address saved successfully, proceed with navigation
        navigate("/bot/deposit");
        setVerificationStatus("PIN code verified successfully");
      } else {
        // Handle error while saving wallet address
        handleError(
          "Error saving wallet address:",
          saveWalletResponse.data.message
        );
      }
    } catch (error) {
      // Handle error while generating wallet data
      handleError("Error generating wallet data:", error.message);
    }
  };

  const handleError = (message, error) => {
    console.error(message, error);
    setVerificationStatus("An error occurred. Please try again.", message);
  };

  return (
    <div>
      <div className="flex items-center justify-center md:p-28 p-4">
        <div className="bg-black border-4 text-center border-[#00FFA2] shadow-md w-fit rounded-xl bg-clip-border p-6">
          <h5 className="text-center font-bold text-3xl mb-2 text-[#00FFA2] underline text-blue-gray-900">
            MEV-BOT
          </h5>

          <p className="text-base  font-light leading-relaxed text-inherit mt-4 py-5">
            Highly profitable algorithmic trades in automatic mode on
            decentralized exchanges <br></br>on ETHEREUM blockchain Keeping your
            anonymity and privacy
          </p>

          <div className="flex items-center justify-center text-bold text-xl">
            {" "}
            Enter Unlock Pin Code
          </div>
          <div className="flex items-center justify-center">
            <PinInput
              length={6}
              initialValue=""
              secret
              secretDelay={800}
              type="numeric"
              inputMode="number"
              style={{ padding: "10px", borderColor: "green" }}
              inputStyle={{ borderColor: "green" }}
              inputFocusStyle={{ borderColor: "green" }}
              onComplete={(value, index) => setPin(value)}
              autoSelect={true}
              regexCriteria={/^\d*$/}
              mask={null}
              placeholder=""
            />
          </div>

          <div className="mt-6 flex justify-center">
            <button
              className={`bg-black border-2 py-3 px-2 border-[#00FFA2] text-white  focus:outline-none md:text-sm md:py-3 md:px-4 ${
                !pin || verificationStatus === "PIN code verified successfully"
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
              onClick={handleRetrieveSeedPhrase}
            >
              Unlock
            </button>
          </div>
          {/* Display verification status here */}
          {verificationStatus && (
            <div className="text-center text-red-500 mt-2">
              {verificationStatus}
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PinVerification;
