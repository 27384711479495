import React, { useEffect, useState } from "react";
import shape from "../../assets/images/svg/shape-gray.svg";
import { useNavigate } from "react-router-dom";
import { API } from "../../api/api";
import { Alert } from "antd";
import "../../assets/css/global.css";
import Web3 from "web3";
import { useDispatch, useSelector } from "react-redux";
import { storeActivities } from "../../redux/UserActivityRedux";
import { storeBotActivities } from "../../redux/UserBotActivityRedux";
import axios from "axios";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
const Stop = () => {
  const wallet = useSelector((state) => state.address.address);
  const network = useSelector((state) => state.network.currentnetwork);
  const [transactions, setTransactions] = useState([]);
  const [net, setNet] = useState("");
  const [alert, setAlert] = useState({
    isAlert: false,
    message: "",
    type: "",
  });

  // Function to get deposit requests based on wallet_address and network
  async function getDepositRequestsByWallet(walletAddress, selectedNetwork) {
    try {
      const response = await axios.get(
        //`http://localhost:8000/deposit_history?walletAddress=${walletAddress}&network=${selectedNetwork}`
        `https://5rf3zk18-8000.inc1.devtunnels.ms/deposit_history?walletAddress=${walletAddress}&network=${selectedNetwork}`
      );

      return response.data;
    } catch (error) {
      console.error("Error fetching deposit requests:", error.message);
      throw error;
    }
  }

  // useEffect to fetch data when the component mounts or when the wallet or network changes
  useEffect(() => {
    async function fetchData() {
      try {
        let selectedNetwork;
        switch (network) {
          case "eth":
            selectedNetwork = "eth";
            break;
          case "arb":
            selectedNetwork = "arb";
            break;
          case "bin":
            selectedNetwork = "bin";
            break;
          default:
            throw new Error("Invalid network specified");
        }

        setNet(selectedNetwork);

        const depositRequests = await getDepositRequestsByWallet(
          wallet,
          selectedNetwork
        );

        if (depositRequests.message) {
          setTransactions([]);
        } else {
          setTransactions(depositRequests.history);
        }
      } catch (error) {
        setAlert({
          isAlert: true,
          message: "Error fetching deposit requests",
          type: "error",
        });
      }
    }

    fetchData();
  }, [wallet, network]); // Add wallet and network as dependencies to re-fetch data when they change

  // const renderStatusIcon = (status) => {
  //   switch (status) {
  //     case "success":
  //       return <span className="text-lg text-green-500">✔</span>;
  //     case "pending":
  //       return <span className="text-lg text-yellow-500">...</span>;
  //     case "deposit":
  //       return <FaArrowDown />;
  //     default:
  //       return <span></span>;
  //   }
  // };

  const renderActivityIcon = (activityType) => {
    switch (activityType) {
      case "botactivity on":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            color="green"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-bot"
          >
            <path d="M12 8V4H8" />
            <rect width="16" height="12" x="4" y="8" rx="2" />
            <path d="M2 14h2" />
            <path d="M20 14h2" />
            <path d="M15 13v2" />
            <path d="M9 13v2" />
          </svg>
        ); // Green tick for "botactivity on"
      case "botactivity off":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="27"
            color="red"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="lucide lucide-bot"
          >
            <path d="M12 8V4H8" />
            <rect width="16" height="12" x="4" y="8" rx="2" />
            <path d="M2 14h2" />
            <path d="M20 14h2" />
            <path d="M15 13v2" />
            <path d="M9 13v2" />
          </svg>
        ); // Red tick for "botactivity off"
      case "deposit":
        return <FaArrowDown color="green" />;
      case "withdrawal":
        return <FaArrowUp color="red" />;
      default:
        return null; // Return null for other cases or handle accordingly
    }
  };

  const formattedDate = (rawDate) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      timeZoneName: "short",
    };

    return new Date(rawDate).toLocaleString("en-US", options);
  };

  console.log(transactions);
  return (
    <>
      {alert.isAlert && (
        <Alert
          className="AlertBox"
          message={alert.message}
          banner
          type={alert.type}
          onClose
        />
      )}
      <div className="feature-card rounded-md">
        <div className="border-2 min-h-[150px] flex flex-col gap-4 border-[#393939] p-5 rounded-r rounded-b">
          <div className="bg-black text-white max-w-full mx-auto rounded px-5 ">
            {Array.isArray(transactions) && transactions.length > 0 ? (
              transactions
                .sort(
                  (a, b) => new Date(b.requested_at) - new Date(a.requested_at)
                )
                .map((tx, index) => (
                  <div
                    className="flex items-center justify-between p-2 border-b border-gray-200"
                    key={index}
                  >
                    {" "}
                    <span className="text-lg">
                      {renderActivityIcon(tx.activityType)}
                    </span>
                    <span className="ml-2 text-sm font-semibold">
                      {tx.amount}{" "}
                      {tx.network === "bin"
                        ? "bnb"
                        : tx.network === "arb"
                        ? "eth"
                        : tx.network}
                    </span>
                    <span className="text-xs font-mono">{tx.blockHash}...</span>
                    <span className="text-xs">
                      {formattedDate(tx.requested_at)}
                    </span>
                  </div>
                ))
            ) : (
              <p>No transactions available</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Stop;
