import React, { useState, useEffect } from "react";

export default function General() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchDataFromLocalStorage = () => {
      // Assuming you have previously stored the user information as a JSON string
      const storedUserData = localStorage.getItem("LoginData ");

      // Parse the JSON string to get the user object
      const parsedUser = storedUserData ? JSON.parse(storedUserData) : null;
      console.log(storedUserData);
      setUser(parsedUser);
      setLoading(false);
    };

    fetchDataFromLocalStorage();
  }, []);

  return (
    <div className="mt-20 my-32 bg-black flex justify-center items-center">
      {loading ? (
        <p className="text-[#589B74] text-center font-bold text-2xl">
          Loading...
        </p>
      ) : (
        <div className="bg-black text-[#589B74] text-center font-bold text-2xl">
          UserName:{" "}
          <span className="text-white border-b-2 border-[#589B74]">
            {user ? user?.user?.fname : "No User"}
          </span>
        </div>
      )}
    </div>
  );
}
