import React from 'react'

function FAQItem() {
  return (
    <div>
      <div
    class="relative w-full bg- px-6 bg-black pt-10 pb-8 mt-8 shadow-xl ring-1 ring-gray-900/5 sm:mx-auto sm:max-w-2xl sm:rounded-lg sm:px-10">
    <div class="mx-auto px-5">
        <div class="flex flex-col items-center">

        </div>
        <div class="mx-auto mt-8 grid max-w-xl divide-y divide-neutral-200">
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> I want to understand how your bot works?</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">The MEV Bot tracks transactions on the selected decentralized exchange and finds among these transactions large orders that affect the liquidity in the pool and the price of the token. With an increased transaction fee, it makes a purchase of the same token slightly earlier and at a lower price. Once a large order goes into the blockchain. The price of the token in the liquidity pool increases. And immediately the MEV Bot sells the token at a profit. The bot earns 0.5-5% from each transaction on these MEV attacks. The bot can make up to 20 or more such deals per work cycle. In automatic mode and with minimal risk
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span>Are there any restrictions if I want to withdraw my money?</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">There are no restrictions on the withdrawal of money. You retain full access and control over your wallet. And you can withdraw funds from it at any time. But it is better to wait until the end of the work cycle, when MEV Bot finishes its work. You can set the duration of the work cycle in the MEV Bot settings.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> If I'm not satisfied with something, can I get my money back for the license? </span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">Refunds for licenses are available within 7 days after purchase. Refunds take from 3 days to 10 days. And you may be asked for additional documents.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span>Why does my wallet need a minimum balance? </span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">The MEV Bot automatically finds opportunities for MEV attacks by manipulating the liquidity pool. The higher the starting capital, the more MEV transactions MEV Bot can open simultaneously, and the higher the final profit. Without a minimum balance the MEV Bot simply cannot start working. The cost of the license is paid separately. This is the amount of reward for the MEV Bot 's team.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> I have other tokens in my wallet, what will happen to them when I start MEV bot? </span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">The MEV Bot does not touch any tokens in your wallet. It does not see them. The bot makes transactions using only the main asset of the ETHEREUM blockchain - ETH. Automatically makes MEV attacks, increasing the balance of your wallet in ETH.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> I would like to test the MEV bot before I buy it. Do you have a Trial version</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">FREE TRIAL version of the MEV Bot with all the functionality of the START license is available to you for 12 hours. If you run the bot without purchasing a license, it runs in trial mode.
                    </p>
                </details>
            </div>

            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> If I want to upgrade my license, do I just have to pay the difference in price?</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">We do not provide for license upgrades. The MEV Bot uses customized algorithms for each type of license and your arbitrage wallet is tied to your license. If you want to switch to a new license you will have to pay the full cost of it and create a separate wallet for the new license.
                    </p>
                </details>
            </div>
            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> Can I pay for my license in bitcoins</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">Yes, you can. In addition to bitcoin, you can pay for a license of one of 10 cryptocurrencies of your choice.
                    </p>
                </details>
            </div>

            <div class="py-5">
                <details class="group">
                    <summary class="flex cursor-pointer list-none items-center justify-between font-medium">
                        <span> How does the MEV bot fee payment process work?</span>
                        <span class="transition group-open:rotate-180">
                                <svg fill="none" height="24" shape-rendering="geometricPrecision"
                                    stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="1.5" viewBox="0 0 24 24" width="24">
                                    <path d="M6 9l6 6 6-6"></path>
                                </svg>
                            </span>
                    </summary>
                    <p class="group-open:animate-fadeIn mt-3 text-neutral-600">Fee is automatically calculated from the profit made and paid at the end of each arbitrage cycle or after each transaction. You can specify this in the settings of the bot..
                    </p>
                </details>
            </div>
        </div>
    </div>
</div>
      
    </div>
  )
}

export default FAQItem;
