import React, { useEffect, useState } from "react";
import bit from "../../assets/images/SM/bitcoin-btc-logo.png";
import eth from "../../assets/images/SM/ethereum-eth-logo.png";
import tee from "../../assets/images/SM/tether-usdt-logo.png";
import bnb from "../../assets/images/SM/bnb-bnb-logo.png";
import lit from "../../assets/images/SM/litecoin-ltc-logo.png";
import usd from "../../assets/images/SM/usd-coin-usdc-logo.png";
import dog from "../../assets/images/SM/dogecoin-doge-logo-alternative.png";
import poly from "../../assets/images/SM/polygon-matic-logo.png";
import trust from "../../assets/images/SM/trueusd-tusd-logo.png";
import xrp from "../../assets/images/SM/xrp-xrp-logo.png";
import busd from "../../assets/images/SM/binance-usd-busd-logo.png";
import QRCode from "react-qr-code";
import axios from "axios";
import CryptoConverter from "./CryptoConverter";

const LicenseSelection = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlanDetails, setSelectedPlanDetails] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const useCryptoPrices = () => {
    const [cryptoPrices, setCryptoPrices] = useState({});
    const [error, setError] = useState(null);

    useEffect(() => {
      axios
        .get("https://api.coingecko.com/api/v3/simple/price", {
          params: {
            ids: "bitcoin,ethereum,tether,binancecoin,litecoin,usd-coin,dogecoin,polygon-ecosystem-token,true-usd,ripple,binance-usd",
            vs_currencies: "usd",
          },
        })
        .then((response) => {
          setCryptoPrices(response.data);
        })
        .catch((error) => {
          setError(error.message);
        });
    }, []);

    return { cryptoPrices, error };
  };

  // Function to convert amount
  const convertAmount = (amount, price) => {
    return amount / price;
  };
  // new state for tracking selected image
  const cryptoAddresses = {
    bitcoin: "bc1q84hzwkck9rk6dfjtv9fl3yk7wh407wval4zqg6",
    ethereum: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458",
    litecoin: "ltc1q4jzldnl2k0hte4d3rrrww3semuth7uqtuesuee",
    xrp: "rGzZJ4zsK1JpdNC6CjqWznU5Edzgrb9xZD",
    eth: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual Ethereum address
    tee: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual Tether address
    bnb: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual Binance Coin address
    lit: "ltc1q4jzldnl2k0hte4d3rrrww3semuth7uqtuesuee", // Replace with actual Litecoin address
    usd: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual USD Coin address
    dog: "DMt3bjarT1iFK8JdYaZe5pkPZXEnG4X1Bs", // Replace with actual Dogecoin address
    poly: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual Polygon address
    trust: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual TrueUSD address
    busd: "0xAEC17785d72D729cA5eE734ca6eD8fEb92c57458", // Replace with actual Binance USD address
  };
  const imageData = [
    {
      id: "bit",
      name: "Bitcoin",
      src: bit,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.bitcoin, // Replace with actual Bitcoin address
    },
    {
      id: "eth",
      name: "Ethereum",
      src: eth,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.eth, // Replace with actual Ethereum address
    },
    {
      id: "tee",
      name: "Tether",
      src: tee,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.tee, // Replace with actual Tether address
    },
    {
      id: "bnb",
      name: "binancecoin",
      src: bnb,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.bnb, // Replace with actual Binance Coin address
    },
    {
      id: "lit",
      name: "Litecoin",
      src: lit,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.litecoin, // Replace with actual Litecoin address
    },
    {
      id: "usd",
      name: "usd-coin",
      src: usd,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.usd, // Replace with actual USD Coin address
    },
    {
      id: "dog",
      name: "Dogecoin",
      src: dog,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.dog, // Replace with actual Dogecoin address
    },
    {
      id: "poly",
      name: "polygon-ecosystem-token",
      src: poly,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.poly, // Replace with actual Polygon address
    },
    {
      id: "trust",
      name: "true-usd",
      src: trust,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.trust, // Replace with actual TrueUSD address
    },
    {
      id: "ripple",
      name: "ripple",
      src: xrp,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.xrp, // Replace with actual XRP address
    },
    {
      id: "busd",
      name: "binance-usd",
      src: busd,
      userID: "21633381",
      amount: "50 USD",
      address: cryptoAddresses.busd, // Replace with actual Binance USD address
    },
  ];

  const plans = [
    {
      name: "Start",
      price: 399,
      features: [
        "Capital Limit ~10000$",
        "3-5% profit per Cycle",
        "min start balance: 0.075 ETH",
        "Withdrawal limit: 50% per day",
        "12% Bot Fee",
        "Standard Support",
      ],
      modalContent:
        "This license allows beginners to get acquainted with the bot. Test it and start earning. The maximum capital that the bot will operate with, regardless of the chosen strategy, is limited to $5000. Maximum profit from 50% to 150% per month.",
    },
    {
      name: "Pro",
      price: 999,
      features: [
        "Capital Limit ~10000$",
        "3-10% profit per Cycle",
        "min start balance: 0.075 ETH",
        "Withdrawal limit: 75% per day",
        "7% Bot Fee",
        "24/7 Priority Support",
      ],
      modalContent:
        "Private discord channel for clients. 24/7 premium tech support Maximum profit from 90% to 350% per month. Select the cryptocurrency of payment",
    },
    {
      name: "Max",
      price: 1999,
      features: [
        "Capital Limit no limits",
        "7-20% profit per Cycle",
        "min start balance: 0.075 ETH",
        "Withdrawal limit: 100% per day",
        "02% Bot Fee",
        "24/7 Premium Support",
      ],
      modalContent:
        "No limits on the maximum capital. The choice of those who have been trading and investing in cryptocurrencies for a long time and understand how things work here. Private discord channel for clients. 24/7 premium tech support. Maximum profit from 90% to 350% per month.",
    },
  ];

  const openModal = (plan) => {
    setSelectedPlanDetails(plan);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);

  const openActivateModal = () => {
    setIsActivateModalOpen(true);
  };

  const closeActivateModal = () => {
    setIsActivateModalOpen(false);
  };
  const { cryptoPrices, error } = useCryptoPrices();
  const convertedAmount =
    selectedImage && cryptoPrices[selectedImage.name.toLowerCase()]
      ? convertAmount(
          selectedPlanDetails.price,
          cryptoPrices[selectedImage.name.toLowerCase()].usd
        )
      : null;
  /////////////////
  return (
    <div className="  bg-black min-h-screen p-8 flex justify-center items-center">
      <div className="max-w-3xl mx-auto rounded-xl shadow-2xl overflow-hidden">
        <div class="bg-black text-white p-6 border-b-4 border-[#589B74] text-center font-bold text-2xl">
          Select License
        </div>
        {/* Render each plan */}
        {plans.map((plan) => (
          <div
            key={plan.name}
            className="bg-black text-[#EFEFEF] p-6 border-b border-[#3A444F]"
          >
            <h1 className="text-2xl font-bold text-[#589B74] mb-4">
              {plan.name} ${plan.price} <span className="text-base">/Year</span>
            </h1>
            <ul className="space-y-2 mb-4">
              {plan.features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
            <div className=" z-10 flex justify-center">
              <button
                onClick={() => openModal(plan)}
                className="z-10 px-5 py-2 border-2 border-[#589B74] text-white hover:bg-[#589B74]  transition-all bg-black"
              >
                Buy
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      {isModalOpen && selectedPlanDetails && (
        <div
          className=" inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 fixed"
          id="modal"
        >
          <div
            className="bg-black opacity-0.5 p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto my-8 border-[#393939] border-[3px] overflow-y-auto"
            style={{ maxHeight: "90vh" }}
          >
            {/* Your modal content here */}
            <div className="text-center">
              <h1 className="text-xl font-bold">
                {selectedPlanDetails.name} Plan - ${selectedPlanDetails.price}{" "}
                p/year
              </h1>
              <p className="mt-4 text-left">
                {selectedPlanDetails.modalContent}
              </p>
            </div>
            <div className="mt-8 text-center">
              <p>Select the cryptocurrency of payment</p>
              <div className="flex mt-4 -ml-6 gap-3">
                {imageData.map((data) => (
                  <img
                    key={data.id}
                    src={data.src}
                    alt={data.name}
                    className="w-8 cursor-pointer"
                    onClick={() => handleImageClick(data)}
                  />
                ))}

                {/* Info box for selected image */}

                {/*  */}
              </div>
            </div>
            <div className="mt-8 text-center">
              {selectedImage && (
                <div class="bg-black p-6 rounded-xl border-2 border-[#589B74] shadow-lg max-w-lg mx-auto my-8">
                  <div class="flex justify-start items-center ">
                    <div class="p-3 bg-white shadow">
                      <QRCode
                        value={`crypto:${selectedImage.address}?amount=${convertedAmount}`}
                        size={128}
                      />
                    </div>

                    <div class="ml-6">
                      <div class="mt-2">
                        <p class="text-xl -ml-4 text-white font-bold-500 ">
                          Send
                          <br></br>
                          {convertedAmount.toFixed(6)} {selectedImage.name}
                        </p>
                        <p class="text-sm text-gray-400 mt-1">
                          ( in one payment ) to:
                        </p>
                      </div>
                    </div>
                  </div>
                  <p class=" rounded-lg shadow-inne text-sm text-white font-medium tracking-wide -ml-4 my-4 bg-black">
                    {selectedImage.address}
                  </p>
                  <div class="mt-6 p-4 bg-gray-700 rounded-lg shadow-inner">
                    <p class="text-xs text-gray-300 text-center">
                      If you send any other BTC amount, the payment system will
                      ignore it!
                    </p>
                  </div>

                  <div class="mt-6 flex items-center justify-start">
                    <div class="rounded-full h-5 w-4 bg-[#589B74] mr-3 animate-pulse"></div>

                    <p class="text-sm text-gray-300">Awaiting Payment...</p>
                  </div>
                </div>
              )}

              <h1 className="text-sm mt-9 ">If you have already paid</h1>
              <button
                onClick={openActivateModal}
                className="bg-black border-2 mt-2 border-[#589B74] text-white px-4 py-2 rounded-full"
              >
                Activate your license
              </button>
              {isActivateModalOpen && (
                <div
                  className="inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 fixed"
                  id="activate-modal"
                >
                  <div
                    className="bg-black opacity-0.5 p-8 rounded-lg shadow-lg w-full max-w-lg mx-auto my-8 border-[#393939] border-[3px] overflow-y-auto"
                    style={{ maxHeight: "90vh" }}
                  >
                    <label>Please enter your Transaction Id</label>
                    <input
                      className="text-sm mt-2 sm:text-base placeholder-gray-500 pl-10 pr-4 rounded-lg border border-[#00FFA2] w-fit py-2 focus:outline-none focus:border-green-400 text-white  bg-black"
                      type="text"
                    />
                    <div className="mt-4 text-center">
                      <button
                        onClick={closeActivateModal}
                        className="bg-black text-white px-4 py-2 rounded-full"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="mt-4 text-center">
              <button
                onClick={closeModal}
                className="bg-black text-white px-4 py-2 rounded-full"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LicenseSelection;
