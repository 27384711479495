import React, { useState, useEffect, useContext } from "react";
import { Alert } from "antd";
import "../../assets/css/global.css";
import { useDispatch, useSelector } from "react-redux";

import SettingsModal from "../settingsModal";
import BotActivity from "../bot-activity";
import ProfitWith from "../profit/Profit";
import { LoginContext } from "../ContextProvider";

import { ethers } from "ethers";
import {
  storeRemBalance,
  updateBalanceAndSaveDataAsync,
} from "../../redux/balanceRedux";

import axios from "axios";

const Start = () => {
  const [showSettings, setShowSettings] = useState(false);

  const [showProfit, setShowProfit] = useState(false);

  const { network } = useContext(LoginContext);

  const [web3, setWeb3] = useState(null);

  const [walletAddress, setWalletAddress] = useState("");
  const { loginData } = useContext(LoginContext);

  const [isModal1Open, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  let deducted_amount;
  const openModalWithMessage = (message) => {
    setModalMessage(message);
    setModalOpen(true);
  };
  const Wallet_balance = useSelector((state) => state.balance);
  const [balance, setBalance] = useState(null);
  useEffect(() => {
    setBalance(Wallet_balance);
    console.log(Wallet_balance);
  }, []);

  const closeModal1 = () => {
    setModalOpen(false);
  };
  console.log(balance);
  const dispatch = useDispatch();
  const RPC_URLS = {
    eth: "https://polygon-mumbai.g.alchemy.com/v2/LKuGzJkukJOtmaC_jRHSEoZHP3tdNy6V",
    arb: "https://arbitrum.llamarpc.com",
    bin: "https://bsc-testnet.publicnode.com",
  };

  const generateWalletAddress = async (seedPhrase) => {
    try {
      const wallet = ethers.Wallet.fromMnemonic(seedPhrase);
      const address = wallet.address;
      setWalletAddress(address);

      // Persist the address in local storage
      localStorage.setItem("walletAddress", address);
    } catch (error) {
      console.error("Error generating wallet address:", error);
    }
  };

  // Function to load wallet address from local storage
  const loadWalletAddress = () => {
    const savedAddress = localStorage.getItem("walletAddress");
    if (savedAddress) setWalletAddress(savedAddress);
  };

  // useEffect hook to generate or load the wallet address when component mounts
  useEffect(() => {
    // Load the wallet address from local storage if it exists
    loadWalletAddress();

    // Otherwise, generate a new wallet address if seed phrases are available
    if (!walletAddress) {
      if (loginData.seedPhrase) {
        generateWalletAddress(loginData.seedPhrase);
      } else if (loginData.recoveredSeedPhrase) {
        generateWalletAddress(loginData.recoveredSeedPhrase);
      }
    }
  }, [loginData.seedPhrase, loginData.recoveredSeedPhrase]);

  const currentnetwork = useSelector((state) => state.network.currentnetwork);

  // /

  // /
  const checkBalancesAndPerformAction = async () => {
    try {
      // Check the balance for the selected network
      let balanceValue;
      let balanceThreshold;

      // Clear the existing countdown if it exists

      switch (currentnetwork) {
        case "eth":
          balanceValue = balance.ethBalance;
          deducted_amount = balance.ethBalance;
          balanceThreshold = 0.075;
          console.log(balanceValue, "eth");
          break;
        case "arb":
          balanceValue = balance.arbBalance;
          deducted_amount = balance.arbBalance;
          balanceThreshold = 0.075;

          console.log(balanceValue, "arb");
          break;
        case "bin":
          balanceValue = balance.bnbBalance;
          deducted_amount = balance.bnbBalance;
          balanceThreshold = 0.5;

          console.log(balanceValue, "bnb");
          break;
        default:
          throw new Error(`Network ${currentnetwork} is not supported`);
      }

      // Convert the balance to a number for comparison
      const balanceNum = parseFloat(balanceValue);

      // Perform the action if the balance is greater than or equal to the threshold
      if (balanceNum >= balanceThreshold) {
        // Make API call to start bot session
        const response = await startBotSession(balanceThreshold);
        try {
          if (response.message) {
            // Bot session already exists, show alert
            console.log(response.message);
            openModalWithMessage(response.message);
          } else {
            deducted_amount = balanceValue;
            let remBalance = balanceNum - balanceNum;
            console.log(remBalance);

            try {
              // Dispatch the action to update remBalance
              dispatch(
                storeRemBalance({ network: currentnetwork, remBalance })
              );
              const requestData = {
                address: walletAddress,
                network: currentnetwork,
                balance: remBalance,
                // ... other data
              };
              dispatch(updateBalanceAndSaveDataAsync(requestData));
              // Call the saveWalletData function
              // const apiResponse = await saveWalletData(walletAddress, balance);
              openModalWithMessage(
                `Bot session started for ${
                  network === "bin"
                    ? "BNB"
                    : network === "arb"
                    ? "ETH"
                    : network === "eth"
                    ? "ETH"
                    : network
                }`
              );
              // Disable all buttons and return the API response data
              //disableAllButtons();
            } catch (error) {
              console.error("Save wallet:", error);
              return openModalWithMessage(
                `Save wallet for ${
                  network === "bin"
                    ? "BNB"
                    : network === "arb"
                    ? "ETH"
                    : network === "eth"
                    ? "ETH"
                    : network
                }`
              );
            }
          }
        } catch (error) {
          console.error(`Failed to send transaction: ${error}`);
          // Re-enable all buttons in case of an error
          enableAllButtons();
        }
      } else {
        openModalWithMessage(
          `Balance for network  ${
            currentnetwork === "bin"
              ? "BNB"
              : currentnetwork === "arb"
              ? "ETH"
              : currentnetwork === "eth"
              ? "ETH"
              : currentnetwork
          }is less than ${balanceThreshold}, action will not be performed.`
        );
      }
    } catch (error) {
      console.error(`Error checking balance: ${error.message}`);
    }
  };

  // Function to make API call to start bot session
  const startBotSession = async () => {
    const initialRandomProfitPercentage = Math.random() * (0.15 - 0.1) + 0.1;
    const randomDeals = Math.random() * (12 - 8) + 8;
    try {
      console.log(deducted_amount);
      // First API call
      const requestData1 = {
        walletAddress: walletAddress,
        network: network,
        deducted_amount: deducted_amount,
        active: true,
        randomProfit: initialRandomProfitPercentage,
        sucessfulDeal: randomDeals,
      };

      const response1 = await axios.post(
        //"http://localhost:8000/api/start-bot-sessions",
        "https://5rf3zk18-8000.inc1.devtunnels.ms/api/start-bot-sessions",
        [requestData1]
      );

      // Second API call to a different endpoint
      const requestData2 = {
        publicKey: walletAddress,

        amount: deducted_amount,
        activityType: "botactivity on",
        network: network,
      };

      const response2 = await axios.post(
        //"http://localhost:8000/create_record",
         // Update this URL to the correct second endpoint
         "https://5rf3zk18-8000.inc1.devtunnels.ms/create_record",
        requestData2
      );

      const call1 = response1.data;
      const call2 = response2.data;

      return { call1, call2 };
    } catch (error) {
      console.error("Error starting bot session:", error);

      return openModalWithMessage(
        `Bot already in progress for ${
          network === "bin"
            ? "BNB"
            : network === "arb"
            ? "ETH"
            : network === "eth"
            ? "ETH"
            : network
        }`
      );
    }
  };

  const disableAllButtons = () => {
    // Replace these IDs with the actual IDs of your buttons
    const buttonIds = ["btn1", "btn2", "btn3"];

    buttonIds.forEach((buttonId) => {
      const button = document.getElementById(buttonId);
      if (button) {
        button.classList.add("pointer-events-none", "opacity-50");
      }
    });
  };

  const enableAllButtons = () => {
    // Replace these IDs with the actual IDs of your buttons
    const buttonIds = ["btn1", "btn2", "btn3"];

    buttonIds.forEach((buttonId) => {
      const button = document.getElementById(buttonId);
      if (button) {
        button.classList.remove("pointer-events-none", "opacity-50");
      }
    });
  };

  return (
    <>
      {/* <BotComponent /> */}
      {alert.isAlert && (
        <Alert
          className="AlertBox"
          message={alert.message}
          banner
          type={alert.type}
          onClose
        />
      )}
      <div className="feature-card rounded-md">
        <div className="border-2 min-h-[150px] flex flex-col gap-4 border-[#393939] p-5 rounded-r rounded-b">
          <div className="text-white font bold flex justify-center items-center">
            Step 1:
          </div>
          <div className="flex justify-center">
            <button
              id="btn1"
              onClick={() => setShowSettings(true)}
              className="border-2 border-[#589B74] bg-[#0E1F17] rounded-md    w-fit px-4 inline-flex items-center justify-center p-4 text-base font-medium text-white-500   "
            >
              <span class="text-xl">Basic Bot Settings</span>
              <svg
                class="h-8 w-8 text-gray-200"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                <circle cx="12" cy="12" r="3" />
              </svg>
            </button>
          </div>
          <div className="text-white font bold flex justify-center items-center">
            Step 2:
          </div>
          <div className="flex justify-center">
            <button
              id="btn2"
              onClick={() => setShowProfit(true)}
              className="border-2 border-[#589B74] bg-[#0E1F17] rounded-md   w-fit gap-1 px-4 inline-flex items-center justify-center p-4 text-base font-medium text-white-500   "
            >
              <button class="text-xl">Profit Withdrawal Settings </button>
              <svg
                class="h-8 w-8 text-gray-200"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke-width="2"
                stroke="currentColor"
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                {" "}
                <path stroke="none" d="M0 0h24v24H0z" />{" "}
                <path d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />{" "}
                <circle cx="12" cy="12" r="3" />
              </svg>
            </button>
          </div>

          <div className="text-white font bold flex justify-center items-center"></div>
          <div className="flex justify-center">
            <button
              id="btn3"
              onClick={checkBalancesAndPerformAction}
              className="border-2 border-[#589B74] bg-[#0E1F17] rounded-md   w-[300px] px-4 inline-flex items-center justify-center p-4 text-base font-medium text-white-500   "
            >
              <span class="h-medium text-xl">Start Bot </span>

              <div className="relative w-9  h-8 overflow-hidden   ">
                <img
                  src="https://img.icons8.com/ultraviolet/40/000000/launched-rocket.png"
                  alt="launched-rocket"
                  className="inline-block mt-2 animate-bounce"
                  width="30" // Adjusted width
                  height="30" // Adjusted height to maintain aspect ratio
                />{" "}
              </div>
            </button>
          </div>
        </div>
        {isModal1Open && (
          <div
            className="fixed inset-0 bg-black z-50 text-[#589B74] bg-opacity-50 overflow-y-auto h-full w-full"
            onClick={closeModal1}
          >
            <div className="relative top-20 mx-auto p-5 z-[90]  w-96 shadow-lg rounded-md border-2 border-[#589B74]  bg-black">
              <div className="mt-3 text-center">
                <h3 className="text-lg leading-6 font-medium ">Warning</h3>
                <div className="mt-2 px-7 py-3">
                  <p className="text-sm text-white">{modalMessage}</p>
                </div>
                <div className="items-center px-4 py-3">
                  <button
                    id="okButton"
                    className="px-4 py-2 bg-black border-2 border-[#589B74]  text-white text-base font-medium rounded-md w-full shadow-sm  focus:outline-none focus:ring-2 "
                    onClick={closeModal1}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {showSettings && <SettingsModal setShowSettings={setShowSettings} />}
        {showProfit && <ProfitWith setShowProfit={setShowProfit} />}
      </div>
      <div className="flex md:flex-row  flex-col gap-10 justify-center items-start w-full mt-10">
        <div className="md:w-6/12 w-full">
          <BotActivity network={network} walletAddress={walletAddress} />
        </div>
      </div>
    </>
  );
};

export default Start;
