import React from 'react'
import Start from '../../components/start'

const BotHome = () => {
    return (
        <div>
            <Start/>
        </div>
    )
}

export default BotHome