import React, { createContext, useState, useEffect } from "react";

export const LoginContext = createContext({
  loginData: {
    user: null,
    seedPhrase: "",
    recoveredSeedPhrase: "",
  },
  setLoginData: () => {},
  setRecoveredSeedPhrase: () => {},
  network: "eth",
  setNetwork: () => {},
});

const LoginDataProvider = ({ children }) => {
  const storedUserData = localStorage.getItem("LoginData");
  let parsedUser = null;

  try {
    parsedUser = storedUserData ? JSON.parse(storedUserData).user : null;
  } catch (error) {
    console.error("Error parsing stored user data:", error);
    parsedUser = null;
  }

  const [user, setUser] = useState(parsedUser);

  useEffect(() => {
    setUser((prevUser) => (prevUser !== null ? prevUser : parsedUser || {}));
  }, [parsedUser]);

  const [loginData, setLoginDataState] = useState({
    user: user,
    seedPhrase: "",
    recoveredSeedPhrase: "",
  });

  const [network, setNetwork] = useState("eth");

  useEffect(() => {
    const storedData = localStorage.getItem("persist:root");
    const parsedData = storedData ? JSON.parse(storedData) : {};
    const currentNetwork = parsedData.network && parsedData.network.currentnetwork
      ? JSON.parse(parsedData.network.currentnetwork)
      : "eth"; // Default to "eth" if network data is not found
    setNetwork(currentNetwork);
  }, []); // Empty dependency array ensures this effect runs only on mount

  const setLoginData = (newLoginData) => {
    setLoginDataState(newLoginData);
    localStorage.setItem("LoginData", JSON.stringify(newLoginData));
  };

  const setRecoveredSeedPhrase = (recoveredSeedPhrase) => {
    setLoginDataState((prevData) => ({
      ...prevData,
      recoveredSeedPhrase,
    }));
  };

  const setNewNetwork = (newNetwork) => {
    setNetwork(newNetwork);
    // Update the network in localStorage
    const storedData = localStorage.getItem("persist:root");
    const parsedData = storedData ? JSON.parse(storedData) : {};
    const updatedData = {
      ...parsedData,
      network: JSON.stringify({ currentnetwork: newNetwork }),
    };
    localStorage.setItem("persist:root", JSON.stringify(updatedData));
  };

  return (
    <LoginContext.Provider
      value={{
        loginData: { user, ...loginData },
        setLoginData,
        setRecoveredSeedPhrase,
        network,
        setNetwork: setNewNetwork,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export default LoginDataProvider;
