import { createSlice } from "@reduxjs/toolkit";

const networkSlice = createSlice({
  name: "network",
  initialState: {
    currentnetwork: "eth",
  },
  // In your reducer
  reducers: {
    storeNetwork: (state, action) => {
      console.log("Action Payload:", action.payload);
      return action.payload;
      // Create a new state object to ensure immutability
    },
  },
});

export const { storeNetwork } = networkSlice.actions;

export default networkSlice.reducer;
