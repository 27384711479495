/* eslint-disable no-console */

import { Switch } from "antd";
import React, { useState, useEffect } from "react";
import { FaArrowRight, FaCog } from "react-icons/fa";
import "../../assets/css/global.css";

const Settings = ({ setShowSettings }) => {
  // Load initial state from localStorage or set default values
  const initialDex = localStorage.getItem("dex") || "dex1";
  const initialStrategy = localStorage.getItem("strategy") || "Limited";
  const initialPoolAge = localStorage.getItem("poolAge") || "Over 1 Year";
  const initialCycle = localStorage.getItem("cycle") || "24 hours";
  const initialPrice = localStorage.getItem("price") || 0;
  const initialPercent = localStorage.getItem("percent") || 0;

  const [dex, setDex] = useState(initialDex);
  const [strategy, setStrategy] = useState(initialStrategy);
  const [poolAge, setPoolAge] = useState(initialPoolAge);
  const [cycle, setCycle] = useState(initialCycle);
  const [price, setPrice] = useState(Number(initialPrice));
  const [percent, setPercent] = useState(Number(initialPercent));

  const handlePriceChange = (e) => {
    const newPrice = e.target.value;
    setPrice(newPrice);
    // Save to localStorage
    localStorage.setItem("price", newPrice);
  };

  const handlePrecentChange = (e) => {
    const newPercent = e.target.value;
    setPercent(newPercent);
    // Save to localStorage
    localStorage.setItem("percent", newPercent);
  };

  const dexbtnactive = (e) => {
    const newDex = e.target.dataset.dex;
    setDex(newDex);
    // Save to localStorage
    localStorage.setItem("dex", newDex);

    const dexbtn = document.querySelectorAll(".dexbtn");
    dexbtn.forEach((btn) => btn.classList.remove("active"));
    e.target.classList.add("active");
  };

  const stratBtnActive = (e) => {
    const newStrategy = e.target.dataset.strategy;
    setStrategy(newStrategy);
    // Save to localStorage
    localStorage.setItem("strategy", newStrategy);

    const stratBtn = document.querySelectorAll(".stratBtn");
    stratBtn.forEach((btn) => btn.classList.remove("active"));
    e.target.classList.add("active");
  };

  const poolBtnActive = (e) => {
    const newPoolAge = e.target.dataset.poolAge;
    setPoolAge(newPoolAge);
    // Save to localStorage
    localStorage.setItem("poolAge", newPoolAge);

    const poolBtn = document.querySelectorAll(".poolBtn");
    poolBtn.forEach((btn) => btn.classList.remove("active"));
    e.target.classList.add("active");
  };

  const cycleBtnActive = (e) => {
    const newCycle = e.target.dataset.cycle;
    setCycle(newCycle);
    // Save to localStorage
    localStorage.setItem("cycle", newCycle);

    const cycleBtn = document.querySelectorAll(".cycleBtn");
    cycleBtn.forEach((btn) => btn.classList.remove("active"));
    e.target.classList.add("active");
  };

  return (
    <>
      <div className="py-6">
        <div className="custom-container container">
          <div className="flex flex-wrap gap-2">
            <div className="  justify-center rounded-md block w-fit items-center overflow-hidden">
              <button className="text-white p-4 tab-btn active rounded border-r mb-2 border-[#393939]">
                Choose Dex
              </button>
              <div className="flex md:flex-row flex-col mt-0 justify-start items-center gap-4">
                <button
                  onClick={dexbtnactive}
                  data-dex="dex1"
                  className={`text-white dexbtn dex1 ${
                    dex === "dex1" ? "active" : ""
                  } p-4 tab-btn w-[200px] h-[50px] rounded border border-[#393939]`}
                ></button>
                <button
                  onClick={dexbtnactive}
                  data-dex="dex2"
                  className={`text-white dexbtn dex2 ${
                    dex === "dex2" ? "active" : ""
                  } p-4 tab-btn w-[200px] h-[50px] rounded border border-[#393939]`}
                ></button>
                {/* <button
                onClick={dexbtnactive}
                data-dex="dex3"
                className={`text-white dexbtn dex3 ${
                  dex === "dex3" ? "active" : ""
                } bg-[#000403] p-4 tab-btn w-[200px] h-[50px] rounded border border-[#393939]`}
              ></button> */}
              </div>
            </div>

            <div className=" w-fit rounded-md block items-center overflow-hidden">
              <button className="text-white p-4 tab-btn active rounded border-r mb-2 border-[#393939]">
                Capital Strategy
              </button>
              <div className="flex md:flex-row flex-col  justify-start items-center gap-4">
                <button
                  onClick={stratBtnActive}
                  data-strategy="Limited"
                  className={`text-white stratBtn ${
                    strategy === "Limited" ? "active" : ""
                  } p-3 tab-btn rounded border border-[#393939]`}
                >
                  Limited
                </button>
                <button
                  onClick={stratBtnActive}
                  data-strategy="Progressive"
                  className={`text-white stratBtn ${
                    strategy === "Progressive" ? "active" : ""
                  } p-3 tab-btn rounded border border-[#393939]`}
                >
                  Progressive
                </button>
              </div>
            </div>
          </div>

          <div className=" mt-6 rounded-md flex items-center overflow-hidden">
            <button className="text-white p-4 tab-btn active rounded border-r border-[#393939]">
              Liquidity Pool Age
            </button>
          </div>
          <div className=" mx-9  mt-7 flex flex-col justify-center items-center">
            <label className="mb-4">
              <span className="font-bold">${price}</span> - Capital Limits:
              (Depends on the license type. Unlimited with MAX license)
              <br></br>
              <i>Splitting the start-up capital into parts.</i>
            </label>
            <input
              type="range"
              value={price}
              min="500"
              max="5000"
              step="1"
              onChange={handlePriceChange}
              className="w-[70%] h-3 bg-gray-200 appearance-none rounded-full transition-all
                  [&::-webkit-slider-runnable-track]:rounded-full  [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[26px] 
                  [&::-webkit-slider-thumb]:w-[26px] [&::-webkit-slider-thumb]:rounded-full
                   [&::-webkit-slider-thumb]:bg-[#589B74]"
            />
          </div>
          <div className=" mx-9  mt-7 flex flex-col justify-center items-center">
            <label className="mb-4">
              <span className="font-bold">{percent}%</span> - Profit per trading
              cycle. (Depends on the license type. Up to 20% with MAX license)
              <br></br>
              <i>
                Looking for trades with a profit not less than the specified.
              </i>
            </label>
            <input
              type="range"
              value={percent}
              min="0"
              max="5"
              step="1"
              onChange={handlePrecentChange}
              className="w-[70%] h-3 bg-gray-200 appearance-none  rounded-full transition-all
                  [&::-webkit-slider-runnable-track]:rounded-full  [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[26px] 
                  [&::-webkit-slider-thumb]:w-[26px] [&::-webkit-slider-thumb]:rounded-full
                   [&::-webkit-slider-thumb]:bg-[#589B74]"
            />
          </div>
          <div className="flex md:flex-row flex-col mt-4 justify-start items-center gap-4">
            <button
              onClick={poolBtnActive}
              data-poolAge="Over 1 Year"
              className={`text-white poolBtn ${
                poolAge === "Over 1 Year" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              Over 1 Year
            </button>
            <button
              onClick={poolBtnActive}
              data-poolAge="Over 6 Months"
              className={`text-white poolBtn ${
                poolAge === "Over 6 Months" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              Over 6 Months
            </button>
            <button
              onClick={poolBtnActive}
              data-poolAge="Over 3 Months"
              className={`text-white poolBtn ${
                poolAge === "Over 3 Months" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              Over 3 Months
            </button>
            <button
              onClick={poolBtnActive}
              data-poolAge="Over 1 Month"
              className={`text-white poolBtn ${
                poolAge === "Over 1 Month" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              Over 1 Month
            </button>
          </div>

          <div className=" mt-6  rounded-md flex items-center overflow-hidden">
            <button className="text-white p-4 tab-btn active rounded border-r border-[#393939]">
              Bot Work Cycle
            </button>
          </div>
          <div className="flex md:flex-row flex-col mt-4 justify-start items-center gap-4">
            <button
              onClick={cycleBtnActive}
              data-cycle="6 hours"
              className={`text-white cycleBtn ${
                cycle === "6 hours" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              6 hours
            </button>
            <button
              onClick={cycleBtnActive}
              data-cycle="12 hours"
              className={`text-white cycleBtn ${
                cycle === "12 hours" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              12 hours
            </button>
            <button
              onClick={cycleBtnActive}
              data-cycle="24 hours"
              className={`text-white cycleBtn ${
                cycle === "24 hours" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              24 hours
            </button>
            <button
              onClick={cycleBtnActive}
              data-cycle="48 hours"
              className={`text-white cycleBtn ${
                cycle === "48 hours" ? "active" : ""
              } p-4 tab-btn rounded border border-[#393939]`}
            >
              48 hours
            </button>
          </div>

          <div className="">
            <div className=" mt-9 flex  flex-wrap justify-between items-center md:gap-4 ">
              <div>
                <button className="text-white p-4 tab-btn active rounded border border-[#393939]">
                  <Switch className="mr-2" /> Exclude Deflationary Tokens
                </button>
              </div>
              <div>
                <button
                  onClick={() => setShowSettings(false)}
                  className="flex py-4 px-6 sv rounded justify-between items-center gap-4 bg-[#0E1F17] border border-[#589B74]"
                >
                  Save <FaArrowRight />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
