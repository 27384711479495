import React from "react";

const Faqs = () => {
  const faqs = [
    {
      id: 9,
      question: "Can you briefly explain how your bot works?",
      answer: (
        <p className="text-white text-xl">
          MEV Bot uses a neural network to find attack opportunities.
The MEV Bot tracks transactions on the selected decentralized exchanges and finds among these transactions large orders that affect the liquidity in the pool and the price of the token. With an increased transaction fee, it makes a purchase of the same token slightly earlier and at a lower price. Once a large order goes into the blockchain. The price of the token in the liquidity pool increases. And immediately the MEV Bot sells the token at a profit. The bot earns 0.5-20% from each transaction on these attacks. The bot can make up to 20 or more such deals per work cycle. In automatic mode and with minimal risk
        </p>
      ),
    },
    {
      id: 1,
      question: "You promise a profit of 2 to 20% a day. What does that depend on? I want 10% instead of 2% ",
      answer: (
        <p className="text-white text-xl">
          Profit depends on the availability of liquidity on decentralized exchanges, bot settings, chosen money management strategy, license and the size of your capital. (the larger it is, the more attacks MEV Bot will be able to perform and the higher the profit will be).
On average, the profit is about 3-5% per work cycle with sufficient capital.
        </p>
      ),
    },
    {
      id: 2,
      question: "Can you tell me the smart contract address of your MEV bot?",
      answer: (
        <p className="text-white text-xl">
          Yes, of course. You can track all MEV bot transactions on the blockchain. And estimate the volume of liquidity and the number of transactions. MEV Bot:{' '}
          <a href="https://etherscan.io/address/0x000000000dfde7deaf24138722987c9a6991e2d4" target="_blank" rel="noopener noreferrer">
            0x000000000dfde7deaf24138722987c9a6991e2d4
          </a>
        </p>
      ),
    },
    {
      id: 3,
      question:
        "Why does Bot`s wallet need a minimum balance?  I paid the cost of the license, is that not enough?",
      answer: (
        <p className="text-white text-xl">
          Attacks require startup capital. MEV Bot automatically finds opportunities for such attacks by buying and selling the matching tokens. The larger the starting capital, the more attacks MEV Bot can launch simultaneously and the higher the final profit. Without a minimum balance, MEV Bot will simply not be able to start. The cost of the license is paid separately. This is a fee for the MEV Bot team, as well as the initial setup and startup fee
        </p>
      ),
    },
    {
      id: 4,
      question: "If I want to upgrade my license, do I just have to pay the difference in price?",
      answer: (
        <p className="text-white text-xl">
          There is no possibility to upgrade licenses. MEV Bot uses individual algorithms for each license type. If you want to upgrade to a new license, you will have to pay the full license fee and create a separate account and wallet for the new license.
Only transferring the license to another wallet is possible.
        </p>
      ),
    },
    {
      id: 5,
      question:
        "Are there any restrictions if I want to withdraw my money?",
      answer: (
        <p className="text-white text-xl">
          You can withdraw funds from the bot wallet at any time. After the bot is launched, the funds are temporarily blocked. And you need to wait for the end of the working cycle. The duration of the work cycle can be set in the MEV Bot settings.
        </p>
      ),
    },
    {
      id: 6,
      question:
        "Ok. If I pay for the license, then I only pay the MEV Bot fee and no more hidden fees?",
      answer: (
        <p className="text-white text-xl">
          Yes, that's right. The license is a one-time fee per year. There are no hidden fees. Everything is out in the open. You only pay the MEV Bot's fee of the profits you make.
        </p>
      ),
    },
    {
      id: 7,
      question:
        "I would like to test the MEV Bot before I buy it. Do you have a Trial version",
      answer: (
        <p className="text-white text-xl">
          Trial version with full functionality of Start license is available to every user. But the bot's commission is 15% of the profit made and the bot's work is limited to one 12-hour work cycle.
        </p>
      ),
    },
    {
      id: 8,
      question: "Can I pay for my license in bitcoins?",
      answer: (
        <p className="text-white text-xl">
          Yes, you can.
In addition to bitcoin, you can pay for a license of one of more than 10 cryptocurrencies

        </p>
      ),
    },
    {
      id: 9,
      question: "How does the ETHEREUM MEV Bot fee payment process work?",
      answer: (
        <p className="text-white text-xl">
          Fee is automatically calculated from the received profit and paid at the end of each working cycle from your wallet or bot's wallet in automatic mode on tariffs START, PRO, MAX
        </p>
      ),
    },
  ];
  return (
    <div className="py-12">
      <div className="custom-container">
        <div className="border border-[#393939] rounded-md flex items-center overflow-hidden">
          <button className="text-white p-4 tab-btn active rounded border-r border-[#393939]">
            Frequently Asked Questions
          </button>
        </div>

        <div className="mt-10">
          {faqs.map((faq) => (
            <div key={faq.id} className="mb-6">
              <div className="flex flex-col gap-4 items-start justify-between">
                <h3 className="text-[#00FFA2] text-2xl font-bold">
                  {faq.question}
                </h3>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Faqs;
