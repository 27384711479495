import React from 'react'
import "./licenseExpiredPop.css"

export default function LicenseExpiredPop() {
    return (
        <>
            <div className='PopUpBackGround'>

                <div className='PopUp'>
                    <h1 className='HeadingText'>License Expired</h1>
                    <p className='DescText'>Your license has expired. Please purchase a license to continue using the MEV bot.</p>
                </div>

            </div>
        </>
    )
}
