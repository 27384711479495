import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Web3 from "web3";
import { API } from "../../api/api";
import "../../assets/css/global.css";
import { Alert } from "antd";

const Buy = () => {
  const [body, setBody] = useState({});
  const [userNetwork, setUserNetwork] = useState("");
  const [alert, setAlert] = useState({});
  const packages = [
    {
      id: 1,
      name: "6 Months",
      popular: false,
      price: 5,
      plan: "per month",
      included: [
        "No Trial Period",
        "Unlock Full Bot Potential",
        "Max Profit per Cycle",
        "24/7 Priority support",
        "15% Bot's fee",
      ],
    },
    {
      id: 2,
      name: "Yearly",
      popular: true,
      price: 10,
      plan: "per month",
      included: [
        "No Trial Period",
        "Unlock Full Bot Potential",
        "Max Profit per Cycle",
        "24/7 Priority support",
        "7% Bot's fee",
      ],
    },
  ];

  const handleAlert = () => {
    setTimeout(() => {
      setAlert({ isAlert: false });
    }, 3000);
  };

  const handleLicense = async (e) => {
    try {
      const resp = await API.userBotLicenseStatus();
      if (resp.status === 200) {
        if (
          resp.data.data.freeTierStatus === "active" ||
          resp.data.data.freeTierStatus === "active"
        ) {
          console.log(resp);
        } else {
          setAlert({
            isAlert: true,
            type: "error",
            title: "Error",
            message: "User Already has an active License",
          });
          handleAlert();
          return;
        }
      }
    } catch (error) {
      setAlert({
        isAlert: true,
        type: "error",
        title: "Error",
        message: error.response.data.message,
      });
      handleAlert();
      console.error(error);
    }

    const network = localStorage.getItem("network");
    if (network === "arb") {
      setUserNetwork("arbitrum");
    } else if (network === "eth") {
      setUserNetwork("ethereum");
    }

    if (network === "arb") {
      const contractAddress = "0x94ce781980C6a76608BC1cc06CCeAe41A88A0961";
      const contractABI = [
        {
          inputs: [],
          stateMutability: "nonpayable",
          type: "constructor",
        },
        {
          inputs: [],
          name: "ReentrancyGuardReentrantCall",
          type: "error",
        },
        {
          inputs: [],
          name: "deposit10",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "deposit5",
          outputs: [],
          stateMutability: "payable",
          type: "function",
        },
        {
          inputs: [],
          name: "owner",
          outputs: [
            {
              internalType: "address payable",
              name: "",
              type: "address",
            },
          ],
          stateMutability: "view",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address payable",
              name: "recipient",
              type: "address",
            },
            {
              internalType: "uint256",
              name: "amount",
              type: "uint256",
            },
          ],
          name: "withdraw",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
        {
          inputs: [
            {
              internalType: "address payable",
              name: "recipient",
              type: "address",
            },
          ],
          name: "withdrawAll",
          outputs: [],
          stateMutability: "nonpayable",
          type: "function",
        },
      ];

      const net = {
        chainId: "0xA4B1",
        chainName: "Arbitrum",
        nativeCurrency: {
          name: "ETH",
          symbol: "ETH",
          decimals: 18,
        },
        rpcUrls: ["https://arbitrum-mainnet.infura.io"], // Replace with your Infura project ID or other RPC endpoint
        blockExplorerUrls: ["hhttps://explorer.arbitrum.io"],
      };

      if (e === "Yearly") {
        if (net.chainId !== "0xA4B1")
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [net],
          });
        if (window.ethereum) {
          try {
            const web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();
            const selectedAccount = accounts[0];
            const contract = new web3.eth.Contract(
              contractABI,
              contractAddress
            );
            const gasPrice = await web3.eth.getGasPrice()      
            const tx = await contract.methods.deposit10().send({
              from: selectedAccount,
              value: web3.utils.toWei("10".toString(), "ether"),
              gasPrice: web3.utils.toHex(gasPrice),
              gasLimit: 200000,
            });
            const hash = tx.hash;
            body.txHash = hash;
            body.networkChain = userNetwork;
            body.type = "Yearly";
            await API.buyLicense(body).then((resp) => {
              if (resp.status === 200) {
                setAlert({
                  isAlert: true,
                  type: "success",
                  title: "Success",
                  message: "License Purchase Successfull",
                });
                handleAlert();
                setBody({});
              }
            });
          } catch (err) {
            console.error("Error buying tokens: ", err);
          }
        }
      }

      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        if (net.chainId !== "0xA4B1")
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [net],
          });
        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const gasPrice = await web3.eth.getGasPrice()
        const tx = await contract.methods.deposit5().send({
          from: selectedAccount,
          value: web3.utils.toWei("5".toString(), "ether"),
          gasPrice: web3.utils.toHex(gasPrice),
          gasLimit: 200000,
        });
        const hash = tx.hash;
        body.txHash = hash;
        body.networkChain = userNetwork;
        console.log(userNetwork);
        body.type = "6Month";
        await API.buyLicense(body).then((resp) => {
          if (resp.status === 200) {
            console.log("ok");
            setAlert({
              isAlert: true,
              type: "success",
              title: "Success",
              message: "License Purchase Successfull",
            });
            handleAlert();
            setBody({});
          }
        });
      } catch (err) {
        console.error("Error buying tokens: ", err);
      }
    }

    const Web3 = require("web3");
    const contractAddress = "0x94ce781980C6a76608BC1cc06CCeAe41A88A0961";

    const contractABI = [
      {
        inputs: [],
        stateMutability: "nonpayable",
        type: "constructor",
      },
      {
        inputs: [],
        name: "ReentrancyGuardReentrantCall",
        type: "error",
      },
      {
        inputs: [],
        name: "deposit10",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "deposit5",
        outputs: [],
        stateMutability: "payable",
        type: "function",
      },
      {
        inputs: [],
        name: "owner",
        outputs: [
          {
            internalType: "address payable",
            name: "",
            type: "address",
          },
        ],
        stateMutability: "view",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address payable",
            name: "recipient",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "amount",
            type: "uint256",
          },
        ],
        name: "withdraw",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
      {
        inputs: [
          {
            internalType: "address payable",
            name: "recipient",
            type: "address",
          },
        ],
        name: "withdrawAll",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    if (e === "Yearly") {
      if (window.ethereum) {
        try {
          const web3 = new Web3(window.ethereum);
          await window.ethereum.enable();
          const accounts = await web3.eth.getAccounts();
          const selectedAccount = accounts[0];
          const contract = new web3.eth.Contract(contractABI, contractAddress);
          const gasPrice = await web3.eth.getGasPrice()
          const tx = await contract.methods.deposit10().send({
            from: selectedAccount,
            value: web3.utils.toWei("10".toString(), "ether"),
            gasPrice: web3.utils.toHex(gasPrice),
            gasLimit: 100000,
          });
          const hash = tx.hash;
          body.txHash = hash;
          body.networkChain = userNetwork;
          console.log(userNetwork);
          body.type = "6Month";
          await API.buyLicense(body).then((resp) => {
            if (resp.status === 200) {
              console.log("ok");
              setAlert({
                isAlert: true,
                type: "success",
                title: "Success",
                message: "License Purchase Successfull",
              });
              handleAlert();
              setBody({});
            }
          });
        } catch (err) {
          console.error("Error buying tokens: ", err);
        }
      }
    }

    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        const accounts = await web3.eth.getAccounts();
        const selectedAccount = accounts[0];
        console.log(selectedAccount);
        const contract = new web3.eth.Contract(contractABI, contractAddress);
        const gasPrice = await web3.eth.getGasPrice()
        const tx = await contract.methods.deposit5().send({
          from: selectedAccount,
          value: web3.utils.toWei("5".toString(), "ether"),
          gasPrice: web3.utils.toHex(gasPrice),
          gasLimit: 100000,
        });
        const hash = tx.hash;
        body.txHash = hash;
        body.networkChain = userNetwork;
        console.log(userNetwork);
        body.type = "6Month";
        await API.buyLicense(body).then((resp) => {
          if (resp.status === 200) {
            console.log("ok");
            setAlert({
              isAlert: true,
              type: "success",
              title: "Success",
              message: "License Purchase Successfull",
            });
            handleAlert();
            setBody({});
          }
        });
      } catch (err) {
        console.error("Error buying tokens: ", err);
      }
    }
  };

  useEffect(() => {
    const network = localStorage.getItem("network");
    if (network === "arb") {
      setUserNetwork("arbitrum");
    } else if (network === "eth") {
      setUserNetwork("ethereum");
    }
  }, []);
  return (
    <>
      {/* {alert.isAlert && (
        <Alert
          className="AlertBox"
          message={alert.message}
          banner
          type={alert.type}
          onClose
        />
      )} */}
<div class="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5">
    <div class=" py-3 md:flex border-4 border-[#589B74] flex items-center justify-center font-bold  text-white  bg-black border-[#00FFA2">
       
    Select Licence
    </div>
    </div>
    <div class="max-w-md mx-auto bg-black  rounded-xl shadow-md overflow-hidden md:max-w-2xl m-5 border-2 border-[#589B74]">
      
   <div className="text-xl">
  <h1 className="flex items-center justify-center text-2xl font-bold text-[#589B74]">Start $399 <span className="text-sm">/Year</span> </h1>
  <div className="text-sm flex items-center  justify-center list-disc">
  <ul className="mt-6 text-white gap-3 list-disc ">
            <li>Capital Limit ~10000$</li>
            <li>3-5% profit per Cycle</li>

            <li>min  start balance: ~$361Usd</li>
            <li>12% Bot Fee</li>
            <li>Standard Support</li>
    </ul>
  </div>
  <div className="py-4">
  <button className="  flex items-center justify-center border-2 p-3 border-[#589B74] bg-black mx-60 py-3 ">Buy</button>
  </div>
   </div>
   <div className=" border-2 border-[#589B74]">
    <div className="text-xl">
  <h1 className="flex items-center justify-center text-2xl font-bold text-[#589B74]">Pro  $999 <span className="text-sm">/Year</span> </h1>
  <div className="text-sm flex items-center  justify-center list-disc">
  <ul className="mt-6 text-white gap-3 list-disc ">
            <li>Capital Limit ~20000$</li>
            <li>3-10% profit per Cycle</li>
            <li>min  start balance: ~$961Usd</li>
            <li>07% Bot Fee</li>
            <li> 24/7 Standard Support</li>
    </ul>
  </div>
  <div className="py-4">
  <button className="  flex items-center justify-center border-2 p-3 border-[#589B74] bg-black mx-60 py-3 ">Buy</button>
  </div>
   </div>
   </div>
   <div className="p">
   <div className=" py-2 border-2 border-[#589B74]">
    <div className="text-xl">
  <h1 className="flex items-center justify-center text-2xl font-bold text-[#589B74]">Pro  $1999 <span className="text-sm">/Year</span> </h1>
  <div className="text-sm flex items-center  justify-center list-disc">
  <ul className="mt-6 text-white gap-3 list-disc ">
            <li>Capital Limit ~1000000$</li>
            <li>7-20% profit per Cycle</li>
            <li>min  start balance: ~$1441Usd</li>
            <li>02% Bot Fee</li>
            <li> 24/7 Standard Support</li>
    </ul>
  </div>
  <div className="py-4">
  <button className="  flex items-center justify-center border-2 p-3 border-[#589B74] bg-black mx-60 py-3 ">Buy</button>
  </div>
   </div>
   </div>
   </div>
    
    </div>


  


  
    
    </>
  );
};

export default Buy;
