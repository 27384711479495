import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const balanceSlice = createSlice({
  name: "balance",
  initialState: {
    ethBalance: 0,
    arbBalance: 0,
    bnbBalance: 0,
  },
  reducers: {
    storeBalance: (state, action) => {
      console.log("Action Payload:", action.payload);
      return action.payload;
    },
    storeRemBalance: (state, action) => {
      const { network, remBalance } = action.payload;
      if (state.hasOwnProperty(network + "Balance")) {
        state[network + "Balance"] = remBalance;
      }
    },
  },
});

export const { storeBalance, storeRemBalance } = balanceSlice.actions;

export const updateBalanceAndSaveDataAsync =
  (requestData) => async (dispatch) => {
    try {
      // Make the API call with the specific network
      const response = await axios.post(
        //`http://localhost:8000/updateNetworkBalance`,
        `https://5rf3zk18-8000.inc1.devtunnels.ms/updateNetworkBalance`,
        requestData
      );

      // Dispatch actions to update the store
      dispatch(
        storeRemBalance({
          network: requestData.network,
          remBalance: requestData.balance,
        })
      );
    } catch (error) {
      console.error("Error updating balance and saving data:", error);
    }
  };

export default balanceSlice.reducer;
