/* eslint-disable no-console */

import React, { useEffect, useState, useContext } from "react";

import { API } from "../../api/api";
import { message } from "antd";
import { Alert } from "antd";
import "../../assets/css/global.css";

import { useDispatch, useSelector } from "react-redux";
import { storeActivities } from "../../redux/UserActivityRedux";
import { storeBotActivities } from "../../redux/UserBotActivityRedux";
import { LoginContext } from "../../components/ContextProvider";

import axios from "axios";
const Withdraw = () => {
  const [body, setBody] = useState({});
  const [alert, setAlert] = useState({});
  const [amount, setAmount] = useState("");

  const dispatch = useDispatch();

  const [speed, setSpeed] = useState(0); // Initialize the speed value
  // //////////////
  const [isOpen, setIsOpen] = useState(false);

  const { network } = useContext(LoginContext);

  // Function to convert Ethereum to USD
  const [ethValue, setEthValue] = useState("");
  const [usdValue, setUsdValue] = useState(null);

  // /////////////////
  const userWalletAddress = useSelector((state) => state.address.address);

  const [receiverAddress, setReceiverAddress] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChangeAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleChangeReceiverAddress = (e) => {
    setReceiverAddress(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if amount or receiverAddress is empty
    if (amount.trim() === "" || receiverAddress.trim() === "") {
      // Use Ant Design message to show an alert
      message.error("Please fill in both amount and receiverAddress fields.");
      return;
    }

    // Set isSubmitting to true during the API call
    setIsSubmitting(true);

    try {
     // const response = await axios.post("http://localhost:8000/withdraw", {
      const response = await axios.post("https://5rf3zk18-8000.inc1.devtunnels.ms/withdraw", {
        userWalletAddress,
        network,
        amount,
        receiverAddress,
      });
      console.log("Withdrawal successful:", response);
      // Handle the response data as needed
      message.success("Withdrawal successful!");
    } catch (error) {
      console.error("Withdrawal failed:", error);
      // Handle the error as needed
      message.error("Withdrawal failed. Please try again.");
    } finally {
      // Set isSubmitting back to false after the API call is complete
      setIsSubmitting(false);
    }
  };

  // ////////////////////

  const fetchEthToUsd = async () => {
    try {
      const response = await fetch(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD"
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setUsdValue(data.USD);
    } catch (error) {
      console.error("Error fetching exchange rate:", error);
    }
  };
  console.log(network, "hhh");
  useEffect(() => {
    fetchEthToUsd();
  }, []);

  ///////
  const handleSpeedChange = (e) => {
    setSpeed(e.target.value);
  };

  const RPC_URLS = {
    eth: "https://polygon-mumbai.g.alchemy.com/v2/LKuGzJkukJOtmaC_jRHSEoZHP3tdNy6V",
    arb: "https://arbitrum.llamarpc.com",
    bin: "https://bsc-testnet.publicnode.com",
  };

  return (
    <>
      {alert.isAlert && (
        <Alert
          className="AlertBox"
          message={alert.message}
          banner
          type={alert.type}
          onClose
        />
      )}
      <div className="feature-card rounded-md">
        <div className="border-2 min-h-[150px] flex flex-wrap justify-center gap-28 border-[#393939] p-5 rounded-r rounded-b">
          <div className=" items-center flex text-center">
            <div>
              <svg
                className="mx-auto"
                xmlns="http://www.w3.org/2000/svg"
                width="61.804"
                height="62.048"
                viewBox="0 0 61.804 62.048"
              >
                <path
                  id="Subtraction_41"
                  data-name="Subtraction 41"
                  d="M-4818.265-4496.952h0a29.8,29.8,0,0,1-11.586-2.322,31.483,31.483,0,0,1-10.552-7.326,31.289,31.289,0,0,1-5.136-7.092,29.964,29.964,0,0,1-2.8-7.766,31.082,31.082,0,0,1,.84-15.854,31.367,31.367,0,0,1,8.632-13.558,30.534,30.534,0,0,1,6.894-4.767,31.888,31.888,0,0,1,8.424-2.841,29.85,29.85,0,0,1,5.541-.522,29.952,29.952,0,0,1,11.514,2.309,31.358,31.358,0,0,1,9.7,6.336,30.664,30.664,0,0,1,9.583,21.219,31.639,31.639,0,0,1-2.1,12.674,29.911,29.911,0,0,1-6.392,9.938A31.541,31.541,0,0,1-4818.265-4496.952Zm2.939-43.048a.807.807,0,0,0-.427.123l0,0a.86.86,0,0,0-.374.373l-.129.244v5.243l-.661.038a17.083,17.083,0,0,0-15.987,15.137c-.175,1.485-.123,2.229.18,2.57a.748.748,0,0,0,.6.227c.032,0,.065,0,.1,0a.889.889,0,0,0,.827-.558,15.67,15.67,0,0,1,5.9-5.868,17.067,17.067,0,0,1,8.353-1.923h.689v2.625c0,3.163.031,3.289.825,3.366h.024a1.027,1.027,0,0,0,.106.007c.388,0,.778-.378,5.552-5.011l.089-.086,1.1-1.069c4.483-4.341,4.483-4.341,4.314-4.859-.013-.042-.027-.084-.04-.133-.027-.1-2.735-2.762-5.241-5.194s-5.271-5.081-5.416-5.15A.8.8,0,0,0-4815.326-4540Z"
                  transform="translate(4848.999 4559)"
                  fill="#fff"
                />
              </svg>

              <p className="mt-3 text-xl text-white mb-3">
                Withdraw {network && network === "bin" ? "BNB" : "ETH"}
              </p>
              <div className="flex justify-center ">
                Current Gas Price: 36.626400455 gwei | $0.14651
              </div>
            </div>
          </div>
          <div className="mb-6 mt-6">
            <input
              type="text"
              name="receiverAddress"
              value={receiverAddress}
              onChange={handleChangeReceiverAddress}
              placeholder={`Send to ${
                network && network === "bin" ? "BNB" : "ETH"
              } Address`}
              className="border-2 text-lg  w-full bg-[#0E1F17] border-[#589B74] placeholder:text-white px-5 py-2 rounded-md p-2"
            />

            <div className="my-3 flex items-center gap-4">
              <div>
                <input
                  type="text"
                  name="amount"
                  value={amount}
                  onChange={handleChangeAmount}
                  className="border-2 text-lg w-full bg-[#0E1F17] border-[#589B74] placeholder:text-white px-5 rounded-md p-2"
                />
              </div>
              <button className="btn py-2 btn-green flex justify-between items-center border-2 border-[#589B74] bg-[#0E1F17] rounded-md px-4">
                <span
                  className="ls-2 text-white"
                  // onClick={(e) => handleWithdraw(e)}
                >
                  half
                </span>
              </button>
              <button className="btn py-2 btn-green flex justify-between items-center border-2 border-[#589B74] bg-[#0E1F17] rounded-md px-4">
                <span
                  className="ls-2 text-white"
                  // onClick={(e) => handleWithdraw(e)}
                >
                  full
                </span>
              </button>
            </div>
            <div>
              <span>
                {usdValue !== null && ethValue !== ""
                  ? `$${(usdValue * parseFloat(ethValue)).toFixed(2)}`
                  : " "}
              </span>
            </div>
            <div className="flex flex-wrap ">
              <div>
                <button
                  disabled={isSubmitting}
                  className="btn mt-4 py-3 btn-green flex w-fit justify-between items-center border-2 border-[#589B74] bg-[#0E1F17] rounded-md px-4"
                  onClick={handleSubmit}
                >
                  <div className="flex justify-start items-center gap-3">
                    <span
                      className="ls-2 text-white  font-bold"
                      // onClick={(e) => handleWithdraw(e)}
                    >
                      WITHDRAW
                    </span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28.812"
                      height="23.957"
                      viewBox="0 0 28.812 23.957"
                    >
                      <path
                        id="path0"
                        d="M11.562,23.831a.856.856,0,0,0,.373-.373l.13-.244V17.971l.66-.038A17.11,17.11,0,0,0,28.712,2.8C28.967.628,28.751-.055,27.833,0c-.405.026-.572.139-.826.559-3.194,5.269-7.789,7.781-14.254,7.791h-.688V5.728c0-3.176-.028-3.29-.825-3.366-.482-.047-.415-.109-5.771,5.089C-.521,13.254-.1,12.786.09,13.511c.052.194,10.372,10.209,10.657,10.345a.807.807,0,0,0,.817-.022"
                        transform="translate(28.812 23.957) rotate(180)"
                        fill="#fff"
                        fill-rule="evenodd"
                      />
                    </svg>
                  </div>
                </button>
              </div>

              <div>
                <div className=" mx-9  mt-7 flex flex-col justify-center items-center">
                  <input
                    type="range"
                    value={speed}
                    min="0"
                    max="100"
                    step="1"
                    onChange={handleSpeedChange}
                    className="w-36 h-3 bg-gray-200 appearance-none rounded-full
                  [&::-webkit-slider-runnable-track]:rounded-full  [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-[26px] 
                  [&::-webkit-slider-thumb]:w-[26px] [&::-webkit-slider-thumb]:rounded-full
                   [&::-webkit-slider-thumb]:bg-[#589B74]
                  "
                  />
                  <div className="flex justify-evenly mt-2 gap-6">
                    <label
                      className={`ml-2 ${speed <= 50 ? "text-green-500" : ""}`}
                    >
                      Slow
                    </label>
                    <label
                      className={`mr-2 ${speed > 50 ? "text-green-500" : ""}`}
                    >
                      Fast
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Withdraw;
