import shape from "../../assets/images/svg/shape-gray.svg";
import { FaArrowRight } from "react-icons/fa";
import "../../assets/css/global.css";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateBalanceAndSaveDataAsync } from "../../redux/balanceRedux";

const BotActivity = ({ walletAddress, network }) => {
  const [remainingTime, setRemainingTime] = useState(0);
  const intervalRef = useRef(null);
  const [successfulDeals, setSuccessfulDeals] = useState(0);
  const [value, setValue] = useState(0);
  const [profitValue, setProfitValue] = useState(0);
  const [cumulativeProfit, setCumulativeProfit] = useState(0);
  const [exchangeValue, setExchangeValue] = useState(0);
  const dispatch = useDispatch();
  const balance = useSelector((state) => state.balance);
  const [showProfit, setShowProfit] = useState(false);
  const [random_p, setRandom_p] = useState(0);
  const [sucessful_d, setSucessful_d] = useState(0);

  const [deducted_amount, setDeducted_amount] = useState(0);

  useEffect(() => {
    const setBalanceValues = () => {
      switch (network) {
        case "eth":
          setValue(balance.ethBalance);
          break;
        case "arb":
          setValue(balance.arbBalance);
          break;
        case "bin":
          setValue(balance.bnbBalance);
          break;
        default:
          throw new Error(`Network ${network} is not supported`);
      }
    };

    setBalanceValues();
  }, [network, balance.ethBalance, balance.arbBalance, balance.bnbBalance]);

  const getBotSessions = async (wallet, net) => {
    try {
      const response = await axios.get(
        //`http://localhost:8000/api/get-bot-sessions/${wallet}/${net}`
        `https://5rf3zk18-8000.inc1.devtunnels.ms/api/get-bot-sessions/${wallet}/${net}`
      );
      setSucessful_d(response.data.sucessfulDeal);
      setRandom_p(response.data.randomProfit);
      setDeducted_amount(response.data.deducted_amount);
      console.log(deducted_amount);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  const calculateRemainingTime = (timestamp) => {
    const targetDate = new Date(timestamp);
    targetDate.setDate(targetDate.getDate() + 1);
    const timeDifference = targetDate.getTime() - Date.now();
    return Math.max(0, Math.floor(timeDifference / 1000));
  };

  const formatTime = (timeInSeconds) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0"
    )}:${String(seconds).padStart(2, "0")}`;
  };

  const startCountdownFromRemainingTime = async (remainingTime) => {
    intervalRef.current = setInterval(async () => {
      try {
        if (remainingTime && remainingTime > 0) {
          remainingTime -= 1;
          setRemainingTime(remainingTime);

          let successfulDeals = 0;
          if (remainingTime === 0) {
          } else if (remainingTime > 0 && remainingTime <= 86400) {
            const intervalDuration = 86400; // 24 hours
            const floatValue = sucessful_d; // fix the variable name
            const roundedValue = Math.floor(floatValue);
            // Calculate the number of successful deals
            const calculatedDeals = Math.floor(
              roundedValue - (remainingTime / intervalDuration) * roundedValue
            );

            // Update successfulDeals to the calculatedDeals value
            setSuccessfulDeals(calculatedDeals);
          }
        } else {
          clearInterval(intervalRef.current);
          intervalRef.current = null;

          // let balanceThreshold;
          // switch (network) {
          //   case "eth":
          //     balanceThreshold = 0.075;
          //     setValue(balanceThreshold);
          //     break;
          //   case "arb":
          //     balanceThreshold = 0.075;
          //     setValue(balanceThreshold);
          //     break;
          //   case "bin":
          //     balanceThreshold = 0.5;
          //     setValue(balanceThreshold);
          //     break;
          //   default:
          //     throw new Error(`Network ${network} is not supported`);
          // }
          const profitValue = deducted_amount * random_p;
          setProfitValue(profitValue);
          const requestData2 = {
            publicKey: walletAddress,
            amount: deducted_amount + profitValue,
            activityType: "botactivity off",
            network: network,
          };

          try {
            const response2 = await axios.post(
              //"http://localhost:8000/create_record",
              "https://5rf3zk18-8000.inc1.devtunnels.ms/create_record",

              requestData2
            );

            const requestData = {
              address: walletAddress,
              network: network,
              balance: value + deducted_amount + profitValue,
              profit: profitValue,
            };

            dispatch(updateBalanceAndSaveDataAsync(requestData));

            const response3 = await axios.delete(
              //`http://localhost:8000/api/delete-bot-session/${walletAddress}/${network}`
              `https://5rf3zk18-8000.inc1.devtunnels.ms/api/delete-bot-session/${walletAddress}/${network}`
            );
          } catch (error) {}
        }
      } catch (error) {}
    }, 1000);
  };

  useEffect(() => {
    setCumulativeProfit(0);

    const fetchDataAndStartCountdown = async () => {
      if (walletAddress && network) {
        try {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
          }

          const botSessionsData = await getBotSessions(walletAddress, network);

          if (botSessionsData) {
            if (botSessionsData.createdAt) {
              const remainingTimeInSeconds = calculateRemainingTime(
                botSessionsData.createdAt
              );

              setRemainingTime(remainingTimeInSeconds);
              startCountdownFromRemainingTime(remainingTimeInSeconds);
            } else {
              const staticTimeInSeconds = 86400;
              setRemainingTime(staticTimeInSeconds);
            }
          } else {
            const staticTimeInSeconds = 86400;
            setRemainingTime(staticTimeInSeconds);
          }
        } catch (error) {}
      }
    };

    fetchDataAndStartCountdown();

    return () => {
      setRemainingTime(0);
      setSuccessfulDeals(0);
      setCumulativeProfit(0);

      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [walletAddress, sucessful_d, network]);

  useEffect(() => {
    getExchangeRate();
  }, [value, successfulDeals, network]);

  const getExchangeRate = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price",
        {
          params: {
            ids: "ethereum,arbitrum,binancecoin",
            vs_currencies: "usd",
          },
        }
      );

      switch (network) {
        case "eth":
          setExchangeValue(response.data.ethereum.usd);
          break;
        case "arb":
          setExchangeValue(response.data.ethereum.usd);
          break;
        case "bin":
          setExchangeValue(response.data.binancecoin.usd);
          break;
        default:
          throw new Error(`Unsupported network: ${network}`);
      }
    } catch (error) {
      setExchangeValue(0);
    }
  };

  useEffect(() => {
    let interval;

    const updateProfit = async () => {
      try {
        // Assuming successfulDeals is the total number of deals, not just the calculated ones.
        const exchangeRate = exchangeValue;
        const remainingTimePercentage = remainingTime / 86400;
        const profitValue = deducted_amount * random_p;
        setProfitValue(profitValue);
        const profitInUSD = profitValue * exchangeRate;
        const dynamicProfit = profitInUSD * (1 - remainingTimePercentage); // Use remainingTime directly

        setCumulativeProfit(dynamicProfit);

        // Check if successfulDeals is greater than 1 before showing the profit
        if (successfulDeals > 1) {
          setShowProfit(true);
        }
      } catch (error) {
        // ... (handle error)
      }
    };

    interval = setInterval(updateProfit, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [
    remainingTime,
    successfulDeals,
    exchangeValue,
    deducted_amount,
    random_p,
  ]);

  return (
    <div className="feature-card rounded-md">
      <div className="inline-flex  items-center py-3 pl-4 pr-4 bg-[#393939] rounded-t-md relative">
        <div className="relative z-20 flex justify-start items-center">
          <h1 className="text-white text-xl ml-2">
            Bot Activity : {network === "bin" ? "BNB" : "ETH"}
          </h1>
        </div>
        <img
          src={shape}
          alt=""
          className="shape absolute h-full right-[-50px]"
        />
      </div>

      <div className="border-2  flex flex-col gap-4 border-[#393939] p-5 h-44 rounded-r rounded-b">
        <div className="container mx-auto py-2">
          <div className="flex justify-center space-x-4">
            <div className="bg-black border-2 border-[#589B74] w-2/5 h-32 p-4">
              <p className="text-white font-bold ">Work Cycle End</p>
              <p className="py-3  flex items-center justify-center">
                {formatTime(remainingTime)}
              </p>
            </div>

            <div className="bg-black border-2 border-[#589B74] w-1/3 h-32 p-4">
              <p className="text-white font-bold text-left ">
                Successful Deals
              </p>
              <p className="py-3  flex items-center justify-center">
                {successfulDeals}
              </p>
            </div>

            <div className="bg-black border-2 border-[#589B74]  w-1/3 h-32 p-4">
              <p className="text-white font-bold ">Total Profit </p>
              <p className="py-3 flex items-center justify-center">
                ${" "}
                {successfulDeals && successfulDeals > 0
                  ? cumulativeProfit.toFixed(4)
                  : "0"}{" "}
                <br></br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotActivity;
